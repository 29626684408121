import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Avatar, Space, Typography, Popover, Tooltip } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ContentBody from "../../CustomComponents/ContentBody";
import SearchBar from "../SearchBar";
import AppDrawer from "./AppDrawer";
import Logo from "../../Assets/proactive_logo.svg";
import { UserContext } from "../UserContext";
import { styles, colorCodes } from "../../Styles/index";
import useQuery from "../../Utils/useQuery";
import { getDTAUrl } from "../../Utils/getData";
import { QuestionCircleOutlined } from "@ant-design/icons";
import BetaTag from "../../Styles/Components/BetaTag";

import "./hamburgers/customizedHamburgers.scss";

const { Text } = Typography;

const Title = styled(Text)`
  font-size: ${styles.headerTitleCopy};
`;

const CopyContainer = styled(Row)`
  margin-top: 64px;
  margin-bottom: 42px;
`;

const HelpText = styled(Text)`
  font-style: italic;
`;

export const WhiteBG = styled.div`
  background: #fff;
  padding: 26px 0;
  img.logo {
    max-width: 188px;
  }
  &.isHome {
    background-color: #d4e9c3;
  }
  min-height: 44px;
`;
const RightAlignCol = styled(Col)`
  text-align: right;
`;

const SearchContainer = styled.div`
  min-width: 300px;
`;

const LogoContainer = styled(Col)`
  display: flex;
  a {
    margin: auto 0;
  }
`;

const Hamburger = styled.div`
  width: 20px;
  height: auto;
  margin-top: 4px;
  margin-left: -20px;
`;

const Help = styled.div`
  width: 20px;
  height: auto;
  margin-top: 4px;
  margin-left: -5px;
`;

const ProactiveHeader = () => {
  const [isHome, setIsHome] = useState(false);
  const location = useLocation();
  const username = useContext(UserContext).value?.email;
  const isChannelPartner = useContext(UserContext).value?.role === "CP";
  const [avatarText, setAvatarText] = useState("A");
  const [avatarBackground, setAvatarBackground] = useState('#ccc');
  const DCPView = useQuery().get("view") === "DCP";
  const [appDrawerOpen, setAppDrawerOpen] = useState(false);
  const [DTAUrl, setDTAUrl] = useState<string>('');
  const isImpersonate = useContext(UserContext).value?.impersonate;

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [location]);

  useEffect(() => {
    const avatarText =
      username && username.length > 0 ? username.charAt(0).toUpperCase() : "A";
    setAvatarText(avatarText);
  }, [username]);

  useEffect(() => {
    if (isImpersonate) {
      setAvatarBackground('#ff4d4f')
    } else {
      setAvatarBackground('#ccc')
    }
  }, [isImpersonate]);

  useEffect(() => {
    if (DTAUrl === '') {
      getDTAUrl().then((url) => {
        setDTAUrl(url);
      })
    }
  }, []);

  if (DCPView) {
    return <></>;
  }

  return (
    <WhiteBG className={isHome ? "isHome" : ""}>
      <ContentBody>
        <Row>
          <LogoContainer span={12}>
            <Link to="/">
              <img
                className="logo"
                src={Logo}
                alt="proactive logo"
                style={{ minHeight: "14px", width: "auto" }}
              />
            </Link>
          </LogoContainer>
          {(!isChannelPartner || isImpersonate === true) && (
            <RightAlignCol span={12}>
              <Space direction="horizontal" size="large">
                {!isHome && (
                  <SearchContainer>
                    <SearchBar />
                  </SearchContainer>
                )}
                <Help>
                  <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title="Proactive Portal Help Center">
                    <a
                      href="https://illumina.gitbook.io/proactiveportal/"
                      target="_blank"
                    >
                      <QuestionCircleOutlined style={{ fontSize: '20px', color: `${colorCodes["textGrey"]}` }}/>
                    </a>
                  </Tooltip>
                </Help>
                <Popover
                  content={<AppDrawer control={setAppDrawerOpen} DTAUrl={DTAUrl} />}
                  trigger={"click"}
                  visible={appDrawerOpen}
                  onVisibleChange={setAppDrawerOpen}
                  title="Tools"
                >
                  <Hamburger
                    className={`hamburger hamburger--slider ${
                      appDrawerOpen ? "is-active" : ""
                    }`}
                    // onClick={() => setAppDrawerOpen(!appDrawerOpen)}
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </Hamburger>
                </Popover>
                <Avatar style={{ backgroundColor: avatarBackground }}>{avatarText}</Avatar>
                </Space>
            </RightAlignCol>
          )}
        </Row>
        {isHome && (
          <CopyContainer>
            <Col span={8}>
              <Title>New look and functionality for</Title>
              <br />
              <Title>Illumina Proactive Portal!</Title>
              <br />
              <br />
              <Text>
                Try the easy search for connected accounts, instruments and much more
              </Text>
            </Col>
            {!isChannelPartner && (
              <Col span={8} push={8}>
                <SearchContainer>
                  <SearchBar />
                </SearchContainer>
                <HelpText>try Bloomington, or A00114</HelpText>
              </Col>
            )}
          </CopyContainer>
        )}
      </ContentBody>
    </WhiteBG>
  );
};

const FooterRow = styled(Row)`
  height: 40vh;
  a {
    display: block;
  }
  ul {
    margin: 0px;
    padding: 0px 0px 0px 20px;
    color: ${colorCodes.textGreen};
  }
`;

export const ProactiveFooter = () => {
  const role = useContext(UserContext).value?.role;

  return (
    <ContentBody>
      <FooterRow>
        <Col span={6}>
          <img
            className="logo"
            src={Logo}
            alt="proactive logo"
            style={{ minHeight: "14px", width: "auto" }}
          />
        </Col>
        {role !== "" && role !== "CP" && (
          <Col span={6}>
            <p>HELP TOPICS</p>
            <a
              href="https://illumina.lightning.force.com/lightning/o/innohub__Innovation_Idea__c/list?filterName=00B3l000009EGPLEA4"
              target="_blank"
            >
              Submit your ideas on the Internal Idea Exchange
            </a>
            <ul>
              <li><a
                href="https://urldefense.com/v3/__https:/illumina.lightning.force.com/lightning/r/Knowledge__kav/ka08X000000sqSHQAY/view__;!!HrbR-XT-OQ!RFYR43MkQUZvu405AqN8sD7CXPcuX2vspXVEiXY4hPWp63d7kaGMMzFBKO5s-LW-OUwH5PEanlD4TLOgSphrKw$"
                target="_blank"
              >
                How to submit an idea
              </a>
              </li>
            </ul>
            <a
              href="https://illumina.service-now.com/esp?id=service_status&service=ed442b5f1bde6010363ebaebcc4bcb93&q=Illumina%20Proactive"
              target="_blank"
            >
              Log an issue on ServiceNow
            </a>
            <a
              href="https://illumina.gitbook.io/proactiveportal/"
              target="_blank"
            >
              Help Center&nbsp;&nbsp;
              <BetaTag style={{
                verticalAlign: 'middle',
                marginBottom: '7px'
              }}>
                BETA
              </BetaTag>
            </a>
          </Col>
        )}
      </FooterRow>
    </ContentBody>
  );
};

export default ProactiveHeader;
